import Image, { StaticImageData } from 'next/image';

import RushfitTwoLogo from '@/assets/landing/Rushfit2Logo.png';
import RushfitLogo from '@/assets/landing/RushfitLogo.png';
import StrikeLogo from '@/assets/landing/StrikeLogo.png';

import RushfitImageOne from '@/assets/landing/rushfit-details1.png';
import RushfitImageTwo from '@/assets/landing/Rushfit_Cover.png';
import RushfitImageThree from '@/assets/landing/rushfit-details3.png';

import RushfitTwoImageOne from '@/assets/landing/Rushfit2_01.png';
import RushfitTwoImageTwo from '@/assets/landing/hero-landing.png';
import RushfitTwoImageThree from '@/assets/landing/Rushfit2_02.png';

import StrikeImageOne from '@/assets/landing/Strike_01.png';
import StrikeImageTwo from '@/assets/landing/Strike_Cover.png';
import StrikeImageThree from '@/assets/landing/Strike_02.png';

import BonusImageOne from '@/assets/landing/Bonus_Grappling.png';
import BonusImageTwo from '@/assets/landing/Bonus_Cover.png';
import BonusImageThree from '@/assets/landing/Bonus_PartnerDrills.png';

import HIWRF2One from '@/assets/landing/Rushfit2_HIW_03.png';
import HIWRF2Two from '@/assets/landing/Rushfit2_HIW_02.png';
import HIWRF2Three from '@/assets/landing/Rushfit2_HIW_01.png';

import HIWRFOne from '@/assets/landing/Rushfit_HIW_03.png';
import HIWRFTwo from '@/assets/landing/Rushfit_HIW_02.png';
import HIWRFThree from '@/assets/landing/Rushfit_HIW_01.png';

import HIWSTOne from '@/assets/landing/Strike_HIW_02.png';
import HIWSTTwo from '@/assets/landing/Strike_HIW_03.png';
import HIWSTThree from '@/assets/landing/Strike_HIW_01.png';

export interface ILandingProgram {
  id: string;
  name: string | JSX.Element;
  title: string | JSX.Element;
  description: string;
  images: StaticImageData[];
  howItWorksImages?: StaticImageData[];
  howItWorks?: string;
  about?: {
    title: string;
    text: string[];
  }[];
  info?: (
    | {
        title: string;
        text: string;
        sessions?: undefined;
      }
    | {
        title: string;
        text: string;
        sessions: {
          title: string;
          duration: string;
        }[];
      }
  )[];
  priceText?: string | JSX.Element;
  price?: number | JSX.Element;
  discount?: string;
  purchaseInfo?: string;
}

export const LANDING = {
  SECTION_IDS: {
    ALL_PROGRAMS: 'all_programs',
    JOIN_THE_RUSH: 'join_the_rush',
    RUSHFIT: 'rushfit',
    RUSHFIT_TWO: 'rushfit_two',
    STRIKE: 'strike',
    BONUS_SESSION: 'bonus_session',
  },
  PROGRAM_DETAILS: {
    RUSHFIT: '/program-details?program=rushfit',
    RUSHFIT_TWO: '/program-details?program=rushfit_two',
    STRIKE: '/program-details?program=strike',
    BONUS_SESSION: '/program-details?program=bonus_session',
  },
  VIDEO_URLS: {
    GENERAL: 'https://cdn.jwplayer.com/manifests/P2n80h42.m3u8',
    RUSHFIT: 'https://cdn.jwplayer.com/manifests/fYD81ZSL.m3u8',
    RUSHFIT_TWO: 'https://cdn.jwplayer.com/manifests/TTglL80O.m3u8',
    STRIKE: 'https://cdn.jwplayer.com/manifests/vzbkCSBU.m3u8',
  },
  BONUS_SESSIONS: {
    BONUS_ONE: 'https://cdn.jwplayer.com/manifests/UfOgJhTI.m3u8',
    BONUS_TWO: 'https://cdn.jwplayer.com/manifests/8hKqW5Su.m3u8',
    BONUS_THREE: 'https://cdn.jwplayer.com/manifests/JVw2tqGR.m3u8',
    BONUS_FOUR: 'https://cdn.jwplayer.com/manifests/MtZM0Ylu.m3u8',
  },
};

export const PROGRAMS = {
  RUSHFIT: {
    id: LANDING.SECTION_IDS.RUSHFIT,
    name: (
      <span>
        Rushfit <sup>TM</sup>
      </span>
    ),
    title: <Image src={RushfitLogo} width={352} height={86} alt="" />,
    description:
      'This unique program gives you the opportunity to workout with UFC Hall of Fame legend, Georges St-Pierre, as he is put through his paces by his trainer. The title comes from his nickname, Rush, but it also perfectly describes the high intensity workouts that will challenge you but more importantly, will change you.',
    images: [RushfitImageOne, RushfitImageTwo, RushfitImageThree],
    howItWorks:
      "This 10-week plan mirrors the training camp model of elite fighters, building a foundation and then incrementally increasing workload to develop both a warrior body and a warrior mindset. Starting with 3 workouts per week, sessions will increase in number and intensity every 2 weeks so that by the end you'll be smashing 5 full-on workouts, guaranteeing you hit your fitness goals.",
    howItWorksImages: [HIWRFOne, HIWRFTwo, HIWRFThree],
    about: [
      {
        title: 'Highly effective training',
        text: [
          "Trainer Erik Owings uses highly effective work-to-rest time periods, combining functional fitness exercises with combat movement patterns to improve your energy, enhance mobility, build strength, and burn fat. His style is direct and to the point, creating a no-nonsense approach that will help you to develop a serious attitude, ensuring you get the best out of each workout and therefore achieve the results you strive for. However, as a skilled fitness professional he knows it's not possible to go 'eyeballs out' the whole time so he teaches modifications of many exercises for those who might be struggling.",
        ],
      },
      {
        title: 'Take you to the next level',
        text: [
          "This type of workout is referred to as metabolic conditioning and is proven to improve both health-related and skill-related parameters of fitness, but to achieve all that, no sugar-coating, it's tough. Fortunately, you have in your corner possibly the greatest martial artist who has ever lived, as Georges offers technique tips, motivation, and words of wisdom to get you to the final round and ensure you end feeling truly fighting fit!",
        ],
      },
      {
        title: 'Guidelines',
        text: [
          "Check the space in which you intend to exercise is free from obstruction, there are no slip and trip hazards, the temperature is suitable and there is enough room to accommodate the movements you'll be performing.",
          "Whilst Georges and his colleague's workout barefoot, please be aware they are experienced and have built up the strength in the muscles in and around their feet to achieve this. Therefore, we recommend you wear appropriate activity apparel and footwear, particularly cushioned, flexible, and supportive training shoes.",
        ],
      },
    ],
    info: [
      {
        title: 'LEVEL',
        text: 'Mixed ability level: Blending combat and conditioning drills, these sessions will enable the non-fighters to improve fitness at the same time as learning the basics of martial arts, and provide an avenue for fighters to refine their knowledge and technique whilst ramping up their strength, speed and stamina.',
      },
      {
        title: 'AVAILABILITY',
        text: 'Stream these sessions in your private members area on any desktop/mobile device.',
      },
      {
        title: 'CONTENTS',
        text: 'Every workout eases you in with a warm-up and brings you back to planet earth with a yoga-based cool-down/stretch. Many of the workouts have been cleverly designed to make the most of using just your bodyweight for resistance, but to ensure maximum strength gains, some exercises use dumbbells so ideally you need 2 sets, 1 light and 1 moderately heavy, in the range of 3kgs/5lbs to 12.5kgs/25lbs, according to your own capabilities. Over 6 hours of content.',
        sessions: [
          { title: 'What is Rushfit™', duration: '1m' },
          { title: 'Foundation Moves', duration: '47m' },
          { title: 'Strength & Endurance', duration: '53m' },
          { title: 'Abdominal Strength & Core Conditioning', duration: '53m' },
          { title: 'The Fight Conditioning Workout', duration: '53m' },
          { title: 'Explosive Power Training', duration: '52m' },
          { title: 'Full Body Strength & Conditioning ', duration: '51m' },
          { title: 'Stretching For Flexibility ', duration: '40m' },
          { title: 'Balance & Agility', duration: '39m' },
        ],
      },
    ],
    priceText: (
      <>
        The{' '}
        <span className="font-extrabold">
          Rushfit<sup>TM</sup>
        </span>{' '}
        program comes free of charge when you purchase{' '}
        <span className="font-extrabold">
          Rushfit<sup>TM</sup> 2
        </span>
      </>
    ),
  },
  RUSHFIT_TWO: {
    id: LANDING.SECTION_IDS.RUSHFIT_TWO,
    name: (
      <span>
        Rushfit <sup>TM</sup> 2
      </span>
    ),
    title: <Image src={RushfitTwoLogo} width={510} height={62} alt="" />,
    description:
      "Uniting again and building on the fitness workouts in Rushfit™ that helped UCF legend Georges St-Pierre to transcend beyond being an elite martial artist and turn his body into a genuine fighting machine, this 8-week program adds a technical focus to ensure you'll be challenged in both body and mind.",
    images: [RushfitTwoImageOne, RushfitTwoImageTwo, RushfitTwoImageThree],
    howItWorks:
      'Each session is creatively designed to combine rounds of martial arts techniques interspersed with rounds of complementary fitness exercises, carefully selected to positively impact your stances, strikes and kicks. Pushing both your physical and mental reserves to the edge, whether you fight or not, this is a sure-fire route to releasing the warrior spirit within you and empowering you to win whatever battles life throws at you.',
    howItWorksImages: [HIWRF2One, HIWRF2Two, HIWRF2Three],
    about: [
      {
        title: 'Highly effective training',
        text: [
          "Whether you're new to the fight game, eager to add to your armoury or just looking to refine the finer details of your technique, the MMA 101 session is the ultimate go-to resource, featuring a breakdown and clear explanation of the full fighter's repertoire. From basic to advanced, attack to defense, hands to feet, this educational and enlightening tutorial will help you to understand how to turn your body into a lethal weapon.",
        ],
      },
      {
        title: 'Take you to the next level',
        text: [
          'Phase 1, FUNDAMENTALS, lasts for 4 weeks and features 4 different workouts that include basic combat skills ideal for fitness enthusiasts simply seeking a new way to get their sweat on, but also for the seasoned martial artists who will know that repetition is the mother of skill, so revisiting the basics is essential to achieving mastery.',
        ],
      },
      {
        title: 'Push your limits',
        text: [
          "Phase 2, PRO, brings you another 4 weeks and 4 more varied workouts but the difference here is the progression to more exciting technique combinations, tougher conditioning drills and the challenge to stay with Georges in the final freestyle round. For the hardcore octagon followers, this is a systematic and scientific map to improving your fighting skills, and for the fitness fans, if you managed to survive phase 1, here's your chance to sample some serious combat fitness training and raise your game on every level.",
        ],
      },
    ],
    info: [
      {
        title: 'LEVEL',
        text: 'Mixed ability level: Blending combat and conditioning drills, these sessions will enable the non-fighters to improve fitness at the same time as learning the basics of martial arts, and also provide an avenue for fighters to refine their knowledge and technique whilst ramping up their strength, speed and stamina.',
      },
      {
        title: 'AVAILABILITY',
        text: 'Stream these sessions in your private members area on any desktop/mobile device.',
      },
      {
        title: 'CONTENTS',
        text: 'Every workout eases you in with a warm-up and brings you back to planet earth with a yoga based cool-down/stretch. In Rushfit™ 2, each workout is designed to combine rounds of martial arts techniques interspersed with rounds of complementary fitness exercises, carefully selected to positively impact your stances, strikes and kicks. You will find firing high head kicks to switching body positions on the floor to be able to finish an opponent. Flexibility is clearly a key factor, therefore 2 mobility sessions are also included increasing flexibility through flowing movements and muscle lengthening holds. Not only will this lead to improved sport performance but more importantly it also reduces your risk of injury. Over 5 hours of content.',
        sessions: [
          { title: 'What is Rushfit™ 2', duration: '1m' },
          { title: 'MMA 101', duration: '31m' },
          { title: 'Fundamentals: Session 1', duration: '31m' },
          { title: 'Fundamentals: Session 2', duration: '31m' },
          { title: 'Fundamentals: Session 3', duration: '31m' },
          { title: 'Fundamentals: Session 4', duration: '34m' },
          { title: 'Pro: Session 5', duration: '35m' },
          { title: 'Pro: Session 6', duration: '38m' },
          { title: 'Pro: Session 7', duration: '34m' },
          { title: 'Pro: Session 8', duration: '38m' },
          { title: 'Mobility 1', duration: '26m' },
          { title: 'Mobility 2', duration: '20m' },
        ],
      },
    ],
    priceText: (
      <>
        The{' '}
        <span className="font-extrabold">
          Rushfit<sup>TM</sup> 2
        </span>{' '}
        program is available to purchase for
      </>
    ),
    price: (
      <>
        {' '}
        <span className="linethrough pr-2">$99</span>
        <span>$49</span>
      </>
    ),
    // discount: '50% OFF',
  },
  STRIKE: {
    id: LANDING.SECTION_IDS.STRIKE,
    name: 'Strike',
    title: <Image src={StrikeLogo} width={462} height={145} alt="" />,
    description:
      "Great fighters can't have a chink in their armour, they need to be complete in both attack and defense, prepared both physically and mentally, capable of being both cautious and spontaneous. These are the sort of qualities that stand mixed martial arts legend, Georges St Pierre, apart from his contemporaries. Now you have the chance to walk in his footsteps, developing these attributes, through this truly groundbreaking program.",
    images: [StrikeImageOne, StrikeImageTwo, StrikeImageThree],
    howItWorks:
      "This is a 12-week training camp, made up of 3 progressively challenging stages, each comprising 4 different workouts that you'll perform once per week. The secret sauce is in the design of these workouts, each one focusing on the different skills required to help you win in the cage, on the sports field, in the workplace, or even at playing this crazy game called life.",
    howItWorksImages: [HIWSTOne, HIWSTTwo, HIWSTThree],
    about: [
      {
        title: 'Highly effective training',
        text: [
          "Architects always start with the foundations so it's no surprise this program works from the bottom up, deconstructing all the moving, inter-related parts that add up to make a fearsome combat athlete and improving each one in isolation.",
          'The good news is that by going back to basics, the workouts are accessible and of benefit to both experienced martial artists and regular fitsters alike. Commit to these workouts and anyone can be a title winner.',
        ],
      },
      {
        title: 'Take you to the next level',
        text: [
          "Your route to finding your fight and becoming the best version of you is via workouts of 3 rounds, each 12 minutes in duration, made up of 5 minutes of kickboxing combinations, 3 minutes of agility drills, 3 minutes of floor work and a final 'all out' minute that'll take you beyond what you thought were your limits!",
        ],
      },
      {
        title: 'Push your limits',
        text: [
          'Georges is assisted by international fitness expert Pierre Pozzutto, who cleverly uses a layering approach to each section of the workout, so it starts easy, then builds, ensuring you can master all the moves and achieve maximum gains, every single time you throw down. ',
          'In addition, his carefully curated warm-ups and yoga-based cool-downs help you to stay injury free and in the fight until the final round.',
        ],
      },
    ],
    info: [
      {
        title: 'LEVEL',
        text: 'Mixed ability level: Blending combat and conditioning drills, these sessions will enable the non-fighters to improve fitness at the same time as learning the basics of martial arts, and also provide an avenue for fighters to refine their knowledge and technique whilst ramping up their strength, speed and stamina.',
      },
      {
        title: 'AVAILABILITY',
        text: 'Stream these sessions in your private members area on any desktop/mobile device.',
      },
      {
        title: 'CONTENTS',
        text: "Every workout eases you in with a warm-up and brings you back to planet earth with a yoga based cool-down/stretch. Strike includes 3 steps to help you achieve guaranteed results. Strike 1 - firing up your energy systems and opening your body's movement potential. Strike 2 - developing cardio endurance and improving strength. Strike 3 - mastering technique execution and developing a never say die mindset. No equipment necessary. Over 10 hours of content.",
        sessions: [
          { title: 'What is Strike', duration: '44s' },
          { title: 'Strike: Workout 1', duration: '51m' },
          { title: 'Strike: Workout 2', duration: '52m' },
          { title: 'Strike: Workout 3', duration: '53m' },
          { title: 'Strike: Workout 4', duration: '54m' },
          { title: 'Strike: Workout 5', duration: '51m' },
          { title: 'Strike: Workout 6', duration: '52m' },
          { title: 'Strike: Workout 7', duration: '52m' },
          { title: 'Strike: Workout 8', duration: '54m' },
          { title: 'Strike: Workout 9', duration: '52m' },
          { title: 'Strike: Workout 10', duration: '53m' },
          { title: 'Strike: Workout 11', duration: '52m' },
          { title: 'Strike: Workout 12', duration: '54m' },
        ],
      },
    ],
    priceText: (
      <>
        The <span className="font-extrabold">Strike</span> program is available to purchase for
      </>
    ),
    price: <span>$20</span>,
  },
  BONUS_SESSION: {
    id: LANDING.SECTION_IDS.BONUS_SESSION,
    name: 'BONUS SESSION',
    title: 'Bonus Sessions',
    description:
      'To help take your MMA journey to the next level, you can also get access to 4 BONUS sessions that will provide self-defense tactics, grappling tips, partner drills and core strength to genuinely accelerate your technique and gains.',
    images: [BonusImageOne, BonusImageTwo, BonusImageThree],
  },
};
