import { axiosInstance } from './index';
import Cookies from 'js-cookie';

const swrFetcher = (url: string) =>
  axiosInstance
    .get(url, {
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
      },
    })
    .then((res) => res.data.data);

export default swrFetcher;
