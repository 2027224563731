import { Dispatch, SetStateAction } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

import { IWorkout, IUser, IProgram, IUserProduct } from '@/types/index';

export const countProgramProgress = (workouts: IWorkout[]) => {
  const progressArray = workouts?.map((workout) =>
    Math.floor((workout?.progress * 100) / workout?.duration)
  );
  const completedArray = progressArray.filter((w) => w > 80);
  return Math.floor((completedArray.length * 100) / progressArray.length);
};

export const formattedDuration = (duration: number) => {
  const formattedDuration = dayjs.duration(duration, 'seconds').format('mm');
  if (formattedDuration === '00') {
    return '01';
  }
  return formattedDuration;
};

export const findGooglePlaceResult = (results: any[], name: string) => {
  const result = results.find(
    (obj: { types: string[] }) => obj.types[0] === name && obj.types[1] === 'political'
  );
  return result ? result.short_name : null;
};

export const checkProgramAvailable = (
  user: IUser | null,
  program: IProgram,
  setIsVisible: Dispatch<SetStateAction<boolean>>
) => {
  const programProductsIds = program?.products?.map((p) => p.id);

  const isGlobalBought = user?.products?.some((p: IUserProduct) => p.is_global);
  const findProgram = user?.products?.find((prod) => programProductsIds?.includes(prod.id));
  const findWorkout = program?.workouts?.find((workout) => {
    const workoutProductsIds = workout.products?.map((w) => w.id);
    return user?.products?.find((prod) => workoutProductsIds?.includes(prod.id));
  });

  if (isGlobalBought) {
    setIsVisible(true);
  } else if (!!findProgram) {
    setIsVisible(true);
  } else if (!!findWorkout) {
    setIsVisible(true);
  } else {
    setIsVisible(false);
  }
};

export const checkWorkoutAvailable = (
  userProducts: IUserProduct[] | undefined,

  products: {
    id: number;
    name: string;
    description: string;
    is_global: boolean;
  }[],
  setIsVisible: Dispatch<SetStateAction<boolean>>,
  program: IProgram
) => {
  const programProductsIds = program?.products?.map((p) => p.id);

  const isGlobalBought = userProducts?.some((p: IUserProduct) => p.is_global);
  const findProgram = userProducts?.find((prod) => programProductsIds?.includes(prod.id));
  const findWorkout = userProducts?.find((prod) => {
    const workoutProductsIds = products?.map((p) => p.id);
    return workoutProductsIds?.includes(prod.id);
  });

  if (isGlobalBought) {
    setIsVisible(true);
  } else if (findProgram) {
    setIsVisible(true);
  } else if (findWorkout) {
    setIsVisible(true);
  } else {
    setIsVisible(false);
  }
};
