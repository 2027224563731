import { toast } from 'react-toastify';
import { ToasterType } from '@/types/components/toaster.type';

const notificationToaster = (type: ToasterType, message: string | undefined) =>
  toast[type === ToasterType.ERROR ? 'error' : 'success'](`${message}`, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

export default notificationToaster;
