import { IStripeProduct, IProduct } from '@/types/index';

export const prepareProducts = (products: IStripeProduct[], currency: string) => {
  const updProducts: IProduct[] = [];

  const productsWithCorrectCurrency = products
    .map((prod) => prod.prices.find((p) => p?.currency === currency))
    .filter((pr) => pr?.id && pr);

  // Check if every price has selected currency, if not - default currency is usd
  const checkCurrency = products?.length === productsWithCorrectCurrency?.length ? currency : 'usd';

  for (let i = 0; i < products?.length; i++) {
    const p = products[i];

    const correctPrice: any = p?.prices.find((p) => p?.currency === checkCurrency);

    const importantFields = {
      description: p.description,
      name: p.name,
      currency: correctPrice?.currency,
      metadata: correctPrice?.metadata,
      price_per_unit: correctPrice?.amount / 100,
      price: correctPrice?.amount / 100,
      realCurrency: correctPrice?.currency,
      stripe_id: correctPrice?.stripe_id,
      id: p.id,
      is_global: p.is_global,
    };
    updProducts.push(importantFields);
  }

  return updProducts;
};
