import { NextApiRequest, NextApiResponse } from 'next';
import { axiosInstance } from '.';
import { PAGE } from '@/router/routes';
import { IUser } from '@/types/index';
import { ENDPOINTS } from '@/constants/index';

export interface BasePageProps extends Record<string, unknown> {
  user?: IUser;
  userProducts: {
    id: number;
    name: string;
    description: string;
    is_global: boolean;
  }[];
  token?: string;
  redirect?: {
    permanent: boolean;
    destination: string;
  };
}

export const protectedRoutePropsWrapper = (callback: any) => {
  return async ({ req, res }: { req: NextApiRequest; res: NextApiResponse }) => {
    const token = req.cookies['token'];

    if (token) {
      try {
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const userRes = await axiosInstance.get(ENDPOINTS.USER.SETTINGS);
        const userProductsRes = await axiosInstance.get(ENDPOINTS.USER.PROFILE);

        return callback({
          user: userRes?.data?.data,
          token,
          userProducts: userProductsRes.data.data.products,
        });
      } catch (error) {
        return callback({
          redirect: {
            permanent: false,
            destination: PAGE.OPTIONS_SIGNIN,
          },
          props: {},
        });
      }
    }

    return callback({
      redirect: {
        permanent: false,
        destination: PAGE.OPTIONS_SIGNIN,
      },
      props: {},
    });
  };
};
