import { protectedRoutePropsWrapper, BasePageProps } from './protectedRoutePropsWrapper';
import notificationToaster from './notificationToaster';
import { prepareProducts } from './prepareProducts';
import swrFetcherShort from './swrFetcherShort';
import { formatNumber } from './formatNumber';
import noAuthAxiosInstance from './axios';
import { deepEqual } from './deepEqual';
import swrFetcher from './swrFetcher';
import axiosInstance from './axios';
import { unwrap } from './unwrap';
import {
  countProgramProgress,
  formattedDuration,
  findGooglePlaceResult,
  checkProgramAvailable,
  checkWorkoutAvailable,
} from './global';

export {
  protectedRoutePropsWrapper,
  findGooglePlaceResult,
  checkProgramAvailable,
  checkWorkoutAvailable,
  countProgramProgress,
  noAuthAxiosInstance,
  notificationToaster,
  formattedDuration,
  prepareProducts,
  swrFetcherShort,
  axiosInstance,
  formatNumber,
  swrFetcher,
  deepEqual,
  unwrap,
};
export type { BasePageProps };
