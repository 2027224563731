import { axiosInstance } from './index';
import Cookies from 'js-cookie';

const swrFetcherShort = (url: string) =>
  axiosInstance
    .get(url, {
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
      },
    })
    .then((res) => res.data);

export default swrFetcherShort;
