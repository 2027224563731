import { MAIN_PRODUCT, MAIN_PRODUCT_ID, PAYMENT_UNEXPECTED_ERROR } from './payment';
import { LANDING, PROGRAMS, ILandingProgram } from './landing.const';
import { MOBILE_SCREEN } from './screenSizes';
import { ENDPOINTS } from './endpoints.const';
import { CURRENCY } from './currency';

export {
  PAYMENT_UNEXPECTED_ERROR,
  MAIN_PRODUCT_ID,
  MOBILE_SCREEN,
  MAIN_PRODUCT,
  ENDPOINTS,
  CURRENCY,
  PROGRAMS,
  LANDING,
};
export type { ILandingProgram };
